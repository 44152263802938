:root {
	/* colors */
	--cultured: #f2f4f3;
	--greydark: #101010;
	/* dimensions */
	--appHeight: 100vh;
	--appWidth: 100%;
	--headerHeight: 4rem;
}

html {
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
	/* scroll-behavior: smooth; */
}

html::-webkit-scrollbar {
	width: 0px; /* For Chrome, Safari, and Opera */
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

button,
a {
	background: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	padding: 0;
}

body {
	background: var(--greydark);
	color: var(--cultured);
	font-size: 16px;
	font-family: 'Mukta', sans-serif;
}

#app {
	opacity: 0;
	background: var(--greydark);
	transition: opacity 250ms ease-in-out;
}

@media (max-width: 700px) {
	#app {
		contain: layout !important;
		height: fit-content !important;
	}
}

@media (max-width: 700px) {
	.main:not(.home, .project-page) {
		padding-top: 4rem;
	}
}

section {
	min-height: var(--appHeight);
}

section:not(#hero, #project1, #project2, #project3, #project4) {
	margin-bottom: 20vh;
	padding-top: 4rem;
}

.section-announcer {
	align-self: flex-start;
	margin-bottom: 5rem;
	text-transform: uppercase;
}

.section-no {
	font-size: inherit;
	font-style: italic;
	padding-right: 0.5rem;
	color: #aaa;
}

@media (max-width: 700px) {
	section:not(#project1, #project2, #project3, #project4) {
		margin-bottom: 10vh;
		padding-top: 0;
	}

	.section-announcer {
		font-size: 2rem !important;
		margin-bottom: 3rem;
	}
}

/* HEADER & NAV */
.header {
	height: var(--headerHeight);
	width: 100%;
	padding: 0 3rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	mix-blend-mode: difference;
	z-index: 1;
	opacity: 0;
	transition: all 300ms ease-in-out;
}

.nav {
	display: flex;
	color: var(--cultured);
}

.menu-toggler {
	display: none;
}

.nav-links {
	width: 100%;
	display: inline-flex;
	justify-content: space-between;
}

.nav-item {
	height: 13px;
}

.nav-item,
.mobile-link {
	width: fit-content;
	position: relative;
	text-transform: uppercase;
}

.nav-links .nav-item {
	margin-left: 1rem;
	line-height: 1;
	vertical-align: bottom;
	transition: all 350ms ease;
}

/* .nav-item__active::after {
	content: '';
	width: 100%;
	height: 0.5px;
	position: absolute;
	top: 100%;
	left: 0;
	background: var(--cultured);
} */

@media (max-width: 700px) {
	.header {
		width: 100vw;
		margin: 0;
		padding: 0 1rem;
		z-index: 3;
	}

	.nav {
		display: none;
	}

	.site-name,
	.menu-toggler {
		height: 1rem;
		line-height: 1;
	}

	.menu-toggler {
		display: block;
		width: fit-content;
		text-transform: uppercase;
		mix-blend-mode: difference;
		opacity: 0;
		z-index: 3;
		transition: opacity 500ms ease-in-out;
	}

	/* .mobile-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 90vh;
		padding: 0 1rem;
		background-color: var(--greydark);
		opacity: 0;
		overflow-y: hidden;
		transition: opacity 400ms ease-in-out;
		z-index: -1;
	}

	.mobile-menu.open {
		opacity: 1;
		z-index: 3;
	}

	.mobile-menu-links {
		flex-direction: column-reverse;
		align-items: center;
		display: flex;
		justify-content: center;
		flex: 3;
	}

	.mobile-menu-footer {
		display: flex;
		flex: 0.5;
		flex-direction: column;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
	}

	.mobile-menu-footer p:not(.credentials) {
		padding-bottom: 1rem;
	}

	.mobile-link {
		height: 3.2rem;
		font-size: 2.3rem;
		font-weight: bold;
		text-transform: uppercase;
		opacity: 0;
		transition: opacity 300ms ease-in-out !important;
		/* animation: apparition 0.5s 1s 500ms ease-in-out forwards; */

	/* animation-name: apparition; */

	/* .mobile-link.nav-item__active::after {
		height: 3px;
	} */

	.nav-item:not(.site-name) {
		display: none;
	}
}

/* HOME HERO */
.hero {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 10vh;
}

.hero__content {
	width: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	line-height: 0.9;
}

.hero__content h1 {
	text-transform: uppercase;
	mix-blend-mode: difference;
}

.hero__content h1 .words {
	line-height: 1;
}

.hero__content .location {
	width: 100%;
}

.hero__content .location span {
	justify-content: flex-end;
	text-align: right;
	text-transform: none;
	transform: translateY(3rem);
}

.hero-scene {
	will-change: transform;
	position: absolute;
	z-index: -1;
}

@media (max-width: 700px) {
	.hero {
		min-height: 90vh;
		justify-content: center;
	}

	.hero__content {
		align-items: flex-start;
		padding-top: 1.2rem;
	}
}

/* HOME projects */
.projects {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}

.projects-container {
	display: flex;
	flex-direction: column;
}

@media (max-width: 700px) {
	.projects-container {
		width: 100%;
		flex-direction: column;
	}
}

.project-preview__container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 3rem 0;
}

.project-preview__container:nth-child(even) {
	flex-direction: row-reverse;
}

@media (max-width: 700px) {
	.project-preview__container {
		width: 100%;
		flex-direction: column;
		margin: 0 0 6rem 0;
	}

	.project-preview__container:nth-child(even) {
		flex-direction: column;
	}

	.project-preview__container:last-child {
		margin-bottom: 0;
	}
}

.project-preview__content {
	width: 40%;
	height: 60%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	margin-left: 4rem;
}

@media (max-width: 700px) {
	.project-preview__content {
		width: 100%;
		margin-left: 0;
	}
}

.project-preview__content-head {
	width: 60%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.project-company {
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: normal;
	transform: translate(-35%, -20%);
}

.project-preview__container:nth-child(even) .project-company {
	transform: translate(35%, -20%);
}

@media (max-width: 700px) {
	.project-company,
	.project-preview__container:nth-child(even) .project-company {
		transform: none;
		padding-bottom: 1rem;
	}
	.project-preview__content .project-company {
		padding-top: 1rem;
		letter-spacing: 0.2rem;
	}
}

.project-date {
	color: #aaa;
	padding-left: 3rem;
}

.project-about {
	width: 90%;
	position: relative;
	min-height: 7rem;
	line-height: 1.2;
	text-transform: uppercase;
	font-family: unset;
}

.img-container {
	width: 40%;
	position: relative;
}

.img-ghost {
	width: 105%;
	height: 105%;
	max-width: 105%;
	position: absolute;
	top: -2%;
	left: -5%;
	transform-origin: left;
	background: var(--greydark);
	will-change: transform;
}

.project-preview__img {
	width: 100%;
	max-width: 960px;
	max-height: 1440px;
	display: block;
	aspect-ratio: 2/3;
	object-fit: cover;
	cursor: pointer;
	filter: grayscale(0.4);
}

@media (max-width: 700px) {
	.project-preview__img,
	.img-container {
		width: 100%;
	}
}

.to-project {
	font-size: 3rem;
	padding-top: 3rem;
	border: none;
}

.see-project {
	font-style: italic !important;
	text-transform: uppercase;
}

@media (max-width: 700px) {
	.see-project {
		width: 100%;
		text-align: right;
		padding-top: 1.5rem;
		padding-right: 0;
	}
}

/* PROJECT PAGE */
.project-page {
	width: fit-content;
	overflow-y: hidden !important;
}

.slider {
	width: fit-content;
	height: 100vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	will-change: transform;
}

.slider-item {
	min-width: 80vw;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 5vw;
}

.slider-item > .img-container {
	width: 100%;
}

.slider-item:last-child {
	margin-right: 0;
}

.first-slide,
.last-slide {
	position: relative;
}

.first-slide {
	min-width: 100vw;
	justify-content: space-between;
}

.last-slide {
	min-width: 80vw;
	justify-content: flex-end;
	cursor: pointer;
}

.slider-item--small {
	min-width: 40vw;
}

.slider-item--small > .img-container {
	width: 60%;
}

.slider-item__about {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 5rem;
}

.slider-item__about > p {
	width: 80%;
	font-family: 'Mukta-Regular';
	line-height: 1.4;
	padding-top: 2rem;
}

@media (max-width: 700px) {
	.project-page {
		width: 100%;
		overflow-x: hidden;
		overflow-y: scroll !important;
	}

	.slider {
		width: 100%;
		height: fit-content;
		flex-direction: column;
		padding: 0 1rem;
	}

	.first-slide {
		flex-direction: column;
		margin-bottom: 5rem;
	}

	.first-slide .slider-item__about {
		width: 100%;
		margin: 5rem 0;
		padding: 0 1rem;
	}

	.slider-item__about > p {
		width: 100%;
	}

	.slider-item {
		min-width: 100%;
		position: unset;
		margin-right: 0;
		margin-bottom: 1.5rem;
	}

	.last-slide {
		width: 100%;
		flex-direction: column;
		margin-bottom: 0;
		margin-top: 7rem;
	}

	.last-slide .last-slide__content {
		transform: none;
	}

	.last-slide .next-project-btn {
		padding-top: 0;
	}
}

#project-cover-img,
#project-back-img {
	display: block;
	width: 40vw;
	max-width: 960px;
	max-height: 1440px;
	object-fit: cover;
	aspect-ratio: 2/3;
}

#project-back-img {
	filter: grayscale(1);
	z-index: -1;
}

.project-cover-title,
.project-back-title {
	font-family: 'PlayfairDisplay-Black';
	text-transform: uppercase;
	mix-blend-mode: difference !important;
}

.project-cover-title {
	position: absolute;
	font-size: 6rem;
	top: 5%;
	left: 30%;
}

.project-back-title {
	bottom: 30%;
	right: 30%;
	font-size: 6rem;
	padding-top: 0;
}

@media (max-width: 700px) {
	.project-cover-title,
	.project-back-title {
		font-size: 3rem;
	}

	.project-cover-title {
		left: auto;
		top: 70px;
		z-index: 1;
	}

	.project-back-title {
		transform: translateY(20px);
		z-index: 1;
	}

	#project-cover-img,
	#project-back-img {
		width: 100vw;
	}

	#project-cover-img {
		position: relative;
	}

	#project-back-img {
		height: 300px;
		z-index: -1;
	}
}

.last-slide__content {
	display: flex;
	flex-direction: column;
	transform: translateX(10rem);
}

.next-project-btn {
	text-align: left;
}

.project-activities {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-top: 3rem;
}

.activity-info {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0;
}

.visit-live {
	margin-top: 3rem;
}

@media (max-width: 700px) {
	.next-project-btn {
		text-align: center;
	}

	.project-activities {
		width: 100%;
	}

	.visit-live {
		margin: 3rem auto 0;
	}
}

.scroll-indicator {
	width: fit-content;
	height: fit-content;
	position: absolute;
	bottom: 50px;
	right: 50px;
	color: #aaa;
	font-size: 1rem;
}

.hero > .scroll-indicator {
	top: 90vh;
	right: unset;
}

@media (max-width: 700px) {
	.scroll-indicator {
		display: none;
	}
	.hero > .scroll-indicator {
		display: block;
		top: 85vh;
	}
}

.project-page__asset {
	width: 100%;
	max-width: 960px;
	max-height: 1440px;
	display: block;
	aspect-ratio: 16/9;
	object-fit: contain;
}

.slider-item.slider-item--small .project-page__asset {
	aspect-ratio: 2/3;
}

@media (max-width: 700px) {
	.project-page__asset {
		max-width: 100%;
		aspect-ratio: 2/1;
	}
}

/* ABOUT PAGE */
.about {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}

.about-container {
	display: flex;
	justify-content: space-evenly;
}

.about-container .img-container {
	transform: translateY(120px);
}

@media (max-width: 700px) {
	#about,
	#about__projects {
		min-height: fit-content !important;
	}
	.about-container {
		width: 100%;
		flex-direction: column-reverse;
	}
}

.about__picture {
	flex: 1;
	width: 100%;
	max-width: 960px;
	max-height: 1440px;
	display: block;
	aspect-ratio: 4/3;
	object-fit: contain;
	filter: brightness(1.6);
}

.about__description {
	flex: 2;
}

.about__description p {
	line-height: 1.2;
}

.about__projects-title,
.about-project-item {
	text-transform: uppercase;
}

.about__projects-title {
	text-align: center;
	padding: 7rem 0 4rem;
}

.about__projects-list {
	margin-bottom: 15vh;
}

.about__projects-list li {
	padding: 2.5rem 0;
}

@media (max-width: 700px) {
	.about__description {
		width: 100%;
		margin-bottom: 3rem;
	}

	.about-container .img-container,
	.about__description {
		transform: none;
	}

	.about__picture {
		width: 100%;
		aspect-ratio: 1/1;
	}

	.about__projects-title {
		text-align: center;
		padding: 0 0 4rem;
	}

	.about__projects-list li {
		height: 8rem;
		width: 100%;
		display: inline-flex;
		align-items: center;
	}

	.about__projects-list li span {
		padding-top: 1rem;
	}
}

/* CONTACT PAGE */
.contact {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	font-size: 3rem;
}

.contact__sub {
	width: 100%;
	margin-bottom: 2rem;
	font-size: 2rem;
	text-align: center;
}

.contact__sub .word-line {
	justify-content: center;
}

.contact-area {
	width: 100%;
	margin: 5rem 0;
}

#send-email,
.socials__link {
	word-break: break-all;
	text-decoration: underline;
	text-transform: uppercase;
	line-height: 1;
	text-align: left;
}

.email-copied {
	display: inline-block;
	width: fit-content;
	margin: 1rem 0;
	padding-left: 1rem;
	text-align: center;
	opacity: 0;
	color: #aaa;
	text-decoration: none !important;
	transition: opacity 300ms ease-in-out;
}

@media (max-width: 700px) {
	.contact {
		min-height: calc(var(--appHeight) / 2);
	}

	.contact__home {
		min-height: auto !important;
		align-items: center;
	}

	.contact__home .contact-title {
		padding-bottom: 0.5rem;
	}

	.contact__sub {
		text-align: left;
	}

	.contact-area {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 2rem 0 0;
	}

	.socials {
		align-items: center;
	}

	#send-email,
	.socials__link {
		width: 100%;
		text-align: center;
		font-size: 1.8rem !important;
	}

	#send-email {
		padding-top: 1rem;
	}

	.email-copied {
		width: 100%;
		padding: 0;
	}
}

@media (max-width: 400px) {
	#send-email,
	.socials__link {
		font-size: 1.5rem !important;
	}
}

/* FOOTER */
.footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 3rem;
	text-transform: uppercase;
	place-items: center;
}

.credentials {
	width: fit-content;
}

.footer-socials {
	width: fit-content;
	display: flex;
	justify-content: center;
	transform: translateX(-45%);
}

.footer-socials__link {
	padding: 0 0.2rem;
}

.back-to-top {
	width: fit-content !important;
}

.socials {
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 5rem;
}

@media (max-width: 700px) {
	.footer {
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
		padding: 2rem 0;
	}

	.credentials,
	.footer-socials,
	.back-to-top {
		width: 100%;
		text-align: center;
		padding-bottom: 1rem;
	}

	.footer-socials {
		transform: none;
	}

	.socials {
		gap: 0.3rem;
	}

	.socials li {
		height: 40px;
	}
}

/* PAGE TRANSITIONS */
.page-transition {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: var(--appWidth);
	will-change: transform;
	z-index: 2;
}

.page-transition.following {
	background: var(--greydark);
}

.page-transition.leading {
	background: var(--cultured);
	filter: brightness(3), blur(30px);
}

@media (max-width: 700px) {
	.page-transition {
		right: 0;
	}
}

/* REVEAL */
.smooth-container {
	visibility: hidden;
}

.words {
	line-height: 1.3;
	/* padding-bottom: 0.2rem; */
}

.word-line {
	overflow: hidden;
	display: flex;
}

/* INTERSECTIONS */
@media (max-width: 700px) {
	.project-page__asset,
	/* .project-preview__img, */
	.about__picture {
		opacity: 1;
		/* transform: translateX(-150px);
		transition: transform 0.5s cubic-bezier(0.81, 0.03, 0.25, 0.78), 0.7s opacity cubic-bezier(0.81, 0.03, 0.25, 0.78); */
	}

	/* .intersected {
		opacity: 1;
		transform: translateX(0);
	} */
}

/* UI */
.divider {
	width: 100%;
	height: 0.7px;
	background: hsl(150, 8%, 35%);
	transition: all 300ms ease;
}

.line {
	position: relative;
	text-align: center;
}

.line::before {
	content: '';
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 0.5px;
	background: var(--cultured);
	transform-origin: 100% 0;
	transform: scaleX(0);
	transition: transform 400ms ease;
	transition-delay: 250ms;
}

.line:hover::before {
	transform-origin: 0 0;
	transform: scaleX(1);
}

@media (max-width: 700px) {
	.line:hover::before {
		transform: scaleX(0);
	}
}

