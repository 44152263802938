:root {
  --cultured: #f2f4f3;
  --greydark: #101010;
  --appHeight: 100vh;
  --appWidth: 100%;
  --headerHeight: 4rem;
}

html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

button, a {
  cursor: pointer;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
}

body {
  background: var(--greydark);
  color: var(--cultured);
  font-family: Mukta, sans-serif;
  font-size: 16px;
}

#app {
  opacity: 0;
  background: var(--greydark);
  transition: opacity .25s ease-in-out;
}

@media (max-width: 700px) {
  #app {
    contain: layout !important;
    height: -moz-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .main:not(.home, .project-page) {
    padding-top: 4rem;
  }
}

section {
  min-height: var(--appHeight);
}

section:not(#hero, #project1, #project2, #project3, #project4) {
  margin-bottom: 20vh;
  padding-top: 4rem;
}

.section-announcer {
  text-transform: uppercase;
  align-self: flex-start;
  margin-bottom: 5rem;
}

.section-no {
  font-size: inherit;
  color: #aaa;
  padding-right: .5rem;
  font-style: italic;
}

@media (max-width: 700px) {
  section:not(#project1, #project2, #project3, #project4) {
    margin-bottom: 10vh;
    padding-top: 0;
  }

  .section-announcer {
    margin-bottom: 3rem;
    font-size: 2rem !important;
  }
}

.header {
  height: var(--headerHeight);
  width: 100%;
  mix-blend-mode: difference;
  z-index: 1;
  opacity: 0;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 3rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.nav {
  color: var(--cultured);
  display: flex;
}

.menu-toggler {
  display: none;
}

.nav-links {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.nav-item {
  height: 13px;
}

.nav-item, .mobile-link {
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  position: relative;
}

.nav-links .nav-item {
  vertical-align: bottom;
  margin-left: 1rem;
  line-height: 1;
  transition: all .35s;
}

@media (max-width: 700px) {
  .header {
    width: 100vw;
    z-index: 3;
    margin: 0;
    padding: 0 1rem;
  }

  .nav {
    display: none;
  }

  .site-name, .menu-toggler {
    height: 1rem;
    line-height: 1;
  }

  .menu-toggler {
    width: -moz-fit-content;
    width: fit-content;
    text-transform: uppercase;
    mix-blend-mode: difference;
    opacity: 0;
    z-index: 3;
    transition: opacity .5s ease-in-out;
    display: block;
  }

  .nav-item:not(.site-name) {
    display: none;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10vh;
  display: flex;
}

.hero__content {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  line-height: .9;
  display: flex;
}

.hero__content h1 {
  text-transform: uppercase;
  mix-blend-mode: difference;
}

.hero__content h1 .words {
  line-height: 1;
}

.hero__content .location {
  width: 100%;
}

.hero__content .location span {
  text-align: right;
  text-transform: none;
  justify-content: flex-end;
  transform: translateY(3rem);
}

.hero-scene {
  will-change: transform;
  z-index: -1;
  position: absolute;
}

@media (max-width: 700px) {
  .hero {
    min-height: 90vh;
    justify-content: center;
  }

  .hero__content {
    align-items: flex-start;
    padding-top: 1.2rem;
  }
}

.projects {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  display: flex;
}

.projects-container {
  flex-direction: column;
  display: flex;
}

@media (max-width: 700px) {
  .projects-container {
    width: 100%;
    flex-direction: column;
  }
}

.project-preview__container {
  justify-content: flex-start;
  align-items: center;
  margin: 3rem 0;
  display: flex;
}

.project-preview__container:nth-child(2n) {
  flex-direction: row-reverse;
}

@media (max-width: 700px) {
  .project-preview__container {
    width: 100%;
    flex-direction: column;
    margin: 0 0 6rem;
  }

  .project-preview__container:nth-child(2n) {
    flex-direction: column;
  }

  .project-preview__container:last-child {
    margin-bottom: 0;
  }
}

.project-preview__content {
  width: 40%;
  height: 60%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 4rem;
  display: flex;
}

@media (max-width: 700px) {
  .project-preview__content {
    width: 100%;
    margin-left: 0;
  }
}

.project-preview__content-head {
  width: 60%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.project-company {
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: 1;
  transform: translate(-35%, -20%);
}

.project-preview__container:nth-child(2n) .project-company {
  transform: translate(35%, -20%);
}

@media (max-width: 700px) {
  .project-company, .project-preview__container:nth-child(2n) .project-company {
    padding-bottom: 1rem;
    transform: none;
  }

  .project-preview__content .project-company {
    letter-spacing: .2rem;
    padding-top: 1rem;
  }
}

.project-date {
  color: #aaa;
  padding-left: 3rem;
}

.project-about {
  width: 90%;
  min-height: 7rem;
  text-transform: uppercase;
  font-family: unset;
  line-height: 1.2;
  position: relative;
}

.img-container {
  width: 40%;
  position: relative;
}

.img-ghost {
  width: 105%;
  height: 105%;
  max-width: 105%;
  transform-origin: 0;
  background: var(--greydark);
  will-change: transform;
  position: absolute;
  top: -2%;
  left: -5%;
}

.project-preview__img {
  width: 100%;
  max-width: 960px;
  max-height: 1440px;
  aspect-ratio: 2 / 3;
  object-fit: cover;
  cursor: pointer;
  filter: grayscale(.4);
  display: block;
}

@media (max-width: 700px) {
  .project-preview__img, .img-container {
    width: 100%;
  }
}

.to-project {
  border: none;
  padding-top: 3rem;
  font-size: 3rem;
}

.see-project {
  text-transform: uppercase;
  font-style: italic !important;
}

@media (max-width: 700px) {
  .see-project {
    width: 100%;
    text-align: right;
    padding-top: 1.5rem;
    padding-right: 0;
  }
}

.project-page {
  width: -moz-fit-content;
  width: fit-content;
  overflow-y: hidden !important;
}

.slider {
  width: -moz-fit-content;
  width: fit-content;
  height: 100vh;
  will-change: transform;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.slider-item {
  min-width: 80vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 5vw;
  display: flex;
  position: relative;
}

.slider-item > .img-container {
  width: 100%;
}

.slider-item:last-child {
  margin-right: 0;
}

.first-slide, .last-slide {
  position: relative;
}

.first-slide {
  min-width: 100vw;
  justify-content: space-between;
}

.last-slide {
  min-width: 80vw;
  cursor: pointer;
  justify-content: flex-end;
}

.slider-item--small {
  min-width: 40vw;
}

.slider-item--small > .img-container {
  width: 60%;
}

.slider-item__about {
  width: 40%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5rem;
  display: flex;
}

.slider-item__about > p {
  width: 80%;
  padding-top: 2rem;
  font-family: Mukta-Regular;
  line-height: 1.4;
}

@media (max-width: 700px) {
  .project-page {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll !important;
  }

  .slider {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 0 1rem;
  }

  .first-slide {
    flex-direction: column;
    margin-bottom: 5rem;
  }

  .first-slide .slider-item__about {
    width: 100%;
    margin: 5rem 0;
    padding: 0 1rem;
  }

  .slider-item__about > p {
    width: 100%;
  }

  .slider-item {
    min-width: 100%;
    position: unset;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .last-slide {
    width: 100%;
    flex-direction: column;
    margin-top: 7rem;
    margin-bottom: 0;
  }

  .last-slide .last-slide__content {
    transform: none;
  }

  .last-slide .next-project-btn {
    padding-top: 0;
  }
}

#project-cover-img, #project-back-img {
  width: 40vw;
  max-width: 960px;
  max-height: 1440px;
  object-fit: cover;
  aspect-ratio: 2 / 3;
  display: block;
}

#project-back-img {
  filter: grayscale();
  z-index: -1;
}

.project-cover-title, .project-back-title {
  text-transform: uppercase;
  font-family: PlayfairDisplay-Black;
  mix-blend-mode: difference !important;
}

.project-cover-title {
  font-size: 6rem;
  position: absolute;
  top: 5%;
  left: 30%;
}

.project-back-title {
  padding-top: 0;
  font-size: 6rem;
  bottom: 30%;
  right: 30%;
}

@media (max-width: 700px) {
  .project-cover-title, .project-back-title {
    font-size: 3rem;
  }

  .project-cover-title {
    z-index: 1;
    top: 70px;
    left: auto;
  }

  .project-back-title {
    z-index: 1;
    transform: translateY(20px);
  }

  #project-cover-img, #project-back-img {
    width: 100vw;
  }

  #project-cover-img {
    position: relative;
  }

  #project-back-img {
    height: 300px;
    z-index: -1;
  }
}

.last-slide__content {
  flex-direction: column;
  display: flex;
  transform: translateX(10rem);
}

.next-project-btn {
  text-align: left;
}

.project-activities {
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3rem;
  display: flex;
}

.activity-info {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;
  display: flex;
}

.visit-live {
  margin-top: 3rem;
}

@media (max-width: 700px) {
  .next-project-btn {
    text-align: center;
  }

  .project-activities {
    width: 100%;
  }

  .visit-live {
    margin: 3rem auto 0;
  }
}

.scroll-indicator {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #aaa;
  font-size: 1rem;
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.hero > .scroll-indicator {
  top: 90vh;
  right: unset;
}

@media (max-width: 700px) {
  .scroll-indicator {
    display: none;
  }

  .hero > .scroll-indicator {
    display: block;
    top: 85vh;
  }
}

.project-page__asset {
  width: 100%;
  max-width: 960px;
  max-height: 1440px;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  display: block;
}

.slider-item.slider-item--small .project-page__asset {
  aspect-ratio: 2 / 3;
}

@media (max-width: 700px) {
  .project-page__asset {
    max-width: 100%;
    aspect-ratio: 2 / 1;
  }
}

.about {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  display: flex;
}

.about-container {
  justify-content: space-evenly;
  display: flex;
}

.about-container .img-container {
  transform: translateY(120px);
}

@media (max-width: 700px) {
  #about, #about__projects {
    min-height: -moz-fit-content !important;
    min-height: fit-content !important;
  }

  .about-container {
    width: 100%;
    flex-direction: column-reverse;
  }
}

.about__picture {
  width: 100%;
  max-width: 960px;
  max-height: 1440px;
  aspect-ratio: 4 / 3;
  object-fit: contain;
  filter: brightness(1.6);
  flex: 1;
  display: block;
}

.about__description {
  flex: 2;
}

.about__description p {
  line-height: 1.2;
}

.about__projects-title, .about-project-item {
  text-transform: uppercase;
}

.about__projects-title {
  text-align: center;
  padding: 7rem 0 4rem;
}

.about__projects-list {
  margin-bottom: 15vh;
}

.about__projects-list li {
  padding: 2.5rem 0;
}

@media (max-width: 700px) {
  .about__description {
    width: 100%;
    margin-bottom: 3rem;
  }

  .about-container .img-container, .about__description {
    transform: none;
  }

  .about__picture {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  .about__projects-title {
    text-align: center;
    padding: 0 0 4rem;
  }

  .about__projects-list li {
    height: 8rem;
    width: 100%;
    align-items: center;
    display: inline-flex;
  }

  .about__projects-list li span {
    padding-top: 1rem;
  }
}

.contact {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 3rem;
  display: flex;
}

.contact__sub {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.contact__sub .word-line {
  justify-content: center;
}

.contact-area {
  width: 100%;
  margin: 5rem 0;
}

#send-email, .socials__link {
  word-break: break-all;
  text-transform: uppercase;
  text-align: left;
  line-height: 1;
  text-decoration: underline;
}

.email-copied {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  opacity: 0;
  color: #aaa;
  margin: 1rem 0;
  padding-left: 1rem;
  transition: opacity .3s ease-in-out;
  display: inline-block;
  text-decoration: none !important;
}

@media (max-width: 700px) {
  .contact {
    min-height: calc(var(--appHeight) / 2);
  }

  .contact__home {
    align-items: center;
    min-height: auto !important;
  }

  .contact__home .contact-title {
    padding-bottom: .5rem;
  }

  .contact__sub {
    text-align: left;
  }

  .contact-area {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 0;
    display: flex;
  }

  .socials {
    align-items: center;
  }

  #send-email, .socials__link {
    width: 100%;
    text-align: center;
    font-size: 1.8rem !important;
  }

  #send-email {
    padding-top: 1rem;
  }

  .email-copied {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 400px) {
  #send-email, .socials__link {
    font-size: 1.5rem !important;
  }
}

.footer {
  width: 100%;
  text-transform: uppercase;
  justify-content: space-between;
  place-items: center;
  padding-top: 3rem;
  display: flex;
}

.credentials {
  width: -moz-fit-content;
  width: fit-content;
}

.footer-socials {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  display: flex;
  transform: translateX(-45%);
}

.footer-socials__link {
  padding: 0 .2rem;
}

.back-to-top {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.socials {
  width: 50%;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 5rem;
  display: flex;
}

@media (max-width: 700px) {
  .footer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
  }

  .credentials, .footer-socials, .back-to-top {
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
  }

  .footer-socials {
    transform: none;
  }

  .socials {
    gap: .3rem;
  }

  .socials li {
    height: 40px;
  }
}

.page-transition {
  height: 100%;
  width: var(--appWidth);
  will-change: transform;
  z-index: 2;
  position: fixed;
  inset: 0;
}

.page-transition.following {
  background: var(--greydark);
}

.page-transition.leading {
  background: var(--cultured);
  filter: brightness(3), blur(30px);
}

@media (max-width: 700px) {
  .page-transition {
    right: 0;
  }
}

.smooth-container {
  visibility: hidden;
}

.words {
  line-height: 1.3;
}

.word-line {
  display: flex;
  overflow: hidden;
}

@media (max-width: 700px) {
  .project-page__asset, .about__picture {
    opacity: 1;
  }
}

.divider {
  width: 100%;
  height: .7px;
  background: #526059;
  transition: all .3s;
}

.line {
  text-align: center;
  position: relative;
}

.line:before {
  content: "";
  width: 100%;
  height: .5px;
  background: var(--cultured);
  transform-origin: 100% 0;
  transition: transform .4s .25s;
  position: absolute;
  top: 100%;
  left: 0;
  transform: scaleX(0);
}

.line:hover:before {
  transform-origin: 0 0;
  transform: scaleX(1);
}

@media (max-width: 700px) {
  .line:hover:before {
    transform: scaleX(0);
  }
}

/*# sourceMappingURL=index.c571c5c3.css.map */
